<template>
  <a-card :bordered="false">
    <a-row :gutter="8">
      <a-col :span="24">
        <div class="table-page-search-wrapper">
          <a-form layout="inline" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
            <a-row :gutter="8">
              <a-col :xxl="6" :xl="6" :md="8" :sm="12">
                <a-form-item label="标题">
                  <a-input placeholder="请输入" v-model="queryParam.title" allowClear />
                </a-form-item>
              </a-col>
              <a-col :xxl="6" :xl="6" :md="8" :sm="12">
                <span class="table-page-search-submitButtons">
                  <a-button type="primary" @click="getList(0)">查询</a-button>
                  <a-button style="margin-left: 8px" @click="getList(1)">重置</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
      </a-col>
    </a-row>
    <div class="table-operator">
      <a-button v-if="addEnable" type="primary" icon="plus" @click="onAdd()">新建</a-button>
    </div>
    <a-table
      size="default"
      ref="table"
      rowKey="id"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :columns="columns"
      :data-source="tableList"
      :pagination="page"
      :rowClassName="rowClassName"
      @change="changeTable"
    >
      <span slot="workStatus" slot-scope="text">
        {{ text | typeFilter }}
      </span>
      <span slot="roles" slot-scope="text">
        <span v-for="(item, index) in text" :key="index">{{ item.name }}</span>
      </span>
      <span slot="login.flagEnable" slot-scope="text, record">
        <a-switch v-model="record.login.flagEnable" @change="onChangeStatus(record)" />
      </span>
      <span slot="workStatus" slot-scope="text">
        <a-badge status="warning" v-if="text == 1" text="试用期" />
        <a-badge status="success" v-if="text == 2" text="已转正" />
        <a-badge status="error" v-if="text == 3" text="已离职" />
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button v-if="editEnabel" type="primary" @click="handleEdit(record)"> 编辑 </a-button>
        <a-button v-if="editEnabel" type="primary" @click="handleDel(record)"> 删除 </a-button>
      </span>
    </a-table>
    <a-modal width="60%" :title="title" :visible="visible" :footer="null" @cancel="cancel('ruleForm')">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="标题" prop="title">
          <a-input v-model="form.title" placeholder="请输入标题" />
        </a-form-model-item>
        <a-form-model-item label="版本" prop="upVersion">
          <a-input v-model="form.upVersion" placeholder="请输入版本" />
        </a-form-model-item>
        <a-form-model-item label="描述信息">
          <Tinymce :value.sync="form.content"></Tinymce>
        </a-form-model-item>
        <a-divider></a-divider>
        <a-form-model-item :wrapper-col="{ span: 24, offset: 20 }">
          <a-button style="margin-left: 10px" :loading="confirmLoading" @click="resetForm"> 关闭 </a-button>
          <a-button type="primary" :loading="confirmLoading" @click="handlerClick"> 保存 </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import { STable, SearchTree } from '@/components'
import { getUpgradeRecordList, delUser } from '@/api/system'
import UserModal from './modules/UserModal'
import { checkPermission } from '@/utils/permissions'
import Tinymce from '../../components/Editor/Tinymce.vue'
import { sysUpgradeRecord, delSysUpgradeRecord, editSysUpgradeRecord } from './api/SysStationApi'
const qs = require('qs')
export default {
  name: 'UserList',
  components: {
    SearchTree,
    STable,
    UserModal,
    Tinymce,
  },
  data() {
    return {
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      visible: false,
      confirmLoading: false,
      title: '',
      type: '',
      ID: '',
      // 表单
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      form: {
        title: '',
        upVersion: undefined,
        content: undefined,
      },
      rules: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        upVersion: [{ required: true, message: '请输入版本', trigger: 'blur' }],
      },
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '标题',
          dataIndex: 'title',
        },
        {
          title: '版本',
          dataIndex: 'upVersion',
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          sorter: true,
        },
        {
          title: '操作',
          width: '180px',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      tableList: [],
      addEnable: checkPermission('system:user:add'),
      editEnabel: checkPermission('system:user:edit'),
    }
  },

  created() {
    this.getList()
  },
  methods: {
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },
    getList(num) {
      this.selectedRowKeys = []
      this.selectedRows = []

      if (num == 1) {
        this.queryParam = {}
      }
      let obj = {
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
      }
      let params = Object.assign({}, obj, this.queryParam)
      getUpgradeRecordList(params).then((res) => {
        this.tableList = res.body.records
        this.page.total = res.body.total
      })
    },
    handleEdit(record) {
      console.log(record.id)
      this.title = '编辑'
      this.type = 'edit'
      this.visible = true
      this.ID = record.id
      let keys = Object.keys(this.form)
      sysUpgradeRecord(record.id).then(({ body, code, message }) => {
        if (code === 200) {
          for (const key in body) {
            if (keys.includes(key)) {
              this.form[key] = body[key]
            }
          }
          console.log(JSON.parse(JSON.stringify(this.form)), 'this.form')
        }
      })
    },
    onAdd() {
      this.visible = true
      this.title = '新建'
      this.type = 'add'
      for (const key in this.form) {
        this.form[key] = ''
      }
    },
    handlerClick() {
      this.type === 'add' ? this.onSubmit() : this.onEdit()
      console.log(this.type, 'type')
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true
          this.axios
            .post('/api/base/system/sysUpgradeRecord/add', this.form)
            .then(({ body, code, message }) => {
              if (code === 200) {
                this.visible = false
                this.confirmLoading = false
                this.$message.success(message)
                this.getList()
              }
            })
            .catch(({ body, code, message }) => {
              this.$message.error(message)
              this.confirmLoading = false
            })
        }
      })
    },
    onEdit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log('编辑接口')
          this.confirmLoading = true
          const res_data = Object.assign(this.form)
          console.log(this.ID, 'id')
          res_data.id = this.ID
          editSysUpgradeRecord(res_data)
            .then(({ body, code, message }) => {
              if (code === 200) {
                this.visible = false
                this.confirmLoading = false
                this.$message.success(message)
                this.getList()
              }
            })
            .catch(({ body, code, message }) => {
              this.$message.error(message)
              this.confirmLoading = false
            })
        }
      })
    },
    handleDel(record) {
      delSysUpgradeRecord(record.id).then(({ body, code, message }) => {
        this.$message.success('删除成功')
        this.getList()
      })
    },
    cancel(formName) {
      console.log('关闭')
      this.visible = false
      this.$refs[formName].resetFields()
      for (const key in this.form) {
        this.form[key] = ''
      }
    },
    resetForm() {
      this.visible = false
    },
    // 分页
    changeTable(pagination) {
      this.page = pagination
      this.getList()
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    resetPwd(record) {
      this.$refs.pwdmodal.edit(record)
    },
    onChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
  },
}
</script>

<style scoped>
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
</style>
